<!--右下角的浮标导航-->
<template>
    <div class="nav-wrapper">
        <router-link class="nav-icon-wrapper" to="/check/index">
            <img src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/float-home.png" class="nav-img">
        </router-link>
    </div>
</template>

<script>
  export default {
    name: 'CheckFloatNav'
  }
</script>

<style scoped>
    .nav-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 3.5rem;
        position: fixed;
        right: 1rem;
        bottom: 5rem;
        border-radius: 10rem;
        background: #ffffff;
        box-shadow: 0 0 0.6rem #d2d2d2;
    }
    .nav-img {
        width: 2rem;
        height: 2rem;
    }
</style>
