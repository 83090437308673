<template>
    <div>
        <div class="tab_bar">
            <div :class="['choose',type==='orderID'?'active':'']" @click="switch_tab('订单号核销')">订单号核销</div>
            <div :class="['choose',type==='code'?'active':'']" @click="switch_tab('核销码核销')">核销码核销</div>
        </div>
        <div>
            <div class="divCode">
                <p>{{typeName}}：</p>
                <input v-model="yard" class="inputCode">
            </div>
            <div class="checkCode" @click="check()">确认核销</div>
        </div>
        <check-float-nav></check-float-nav>
    </div>
</template>

<script>
    import CheckFloatNav from '../../components/check/CheckFloatNav'
    import { globalConfig } from '../../utils/mixin'
    export default {
        name: 'Scan',
        mixins: [globalConfig],
        components: { CheckFloatNav },
        data () {
            return {
                yard: '',
                type: 'orderID',
                typeName: '订单号'
            }
        },
        methods: {
            switch_tab: function (status) {
                switch (status) {
                    case '订单号核销':
                        this.type = 'orderID'
                        this.typeName = '订单号'
                        break
                    case '核销码核销':
                        this.type = 'code'
                        this.typeName = '核销码'
                        break
                    default:
                        break
                }
            },
            check: function () {
                this.axios.post(this.apiUrl + 'checkh5/order/isCheckType', {
                    yard: this.yard,
                    type: this.type
                }).then((response) => {
                    console.log(response.data)
                    const data = response.data
                    if (data.status === 1) {
                        this.$router.push('/check/checkList/qr/' + data.id + '/' + data.sign)
                    } else {
                        this.$router.push('/check/warning/' + data.msg)
                    }
                })
            }
        },
        created () {
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    body{
        background-color: #ffffff;
        margin: 0;
        padding: 0;
    }
    .tab_bar{
        height: 2.6rem;
        line-height: 2.6rem;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #ffffff;
        padding: 0 0.5rem;
    }
    .tab_bar .choose{
        font-size: 1rem;
        padding:0.1rem 0.3rem;
        color: #7e7e7e;
    }
    .tab_bar .active{
        border-bottom: 1px solid #ca1416;
        color: #ca1416;
    }
    #business_name{
        width: 100vw;
        padding: 2vw 0;
        border:1px solid #eee;
        text-align: center;
        font-size: 3.6vw;
        font-weight: 600;
    }
    .divCode{
        width: 100vw;
        display: flex;
        margin: 20vw 0  2vw 0;
        justify-content: center;
        align-items: center;
    }
    .inputCode{
        width: 60vw;
        height: 10vw;
        border: 1px solid #999;
        border-radius: 1vw;
        text-indent: 2vw;
        color: #666;
        font-weight: 600;
        font-size: 4.2vw;
    }
    .checkCode{
        width: 80vw;
        height: 10vw;
        margin: 5vw 10vw;
        border-radius: 1vw;
        color: #fff;
        font-weight: 600;
        font-size: 4.2vw;
        background-color: #C20B09;
        line-height: 10vw;
        text-align: center;
    }
</style>
